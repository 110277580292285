.content-container,
.hero-image {
  width: 100vw;
  height: 100vh;
}

.content-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image {
  object-fit: cover;
  object-position: 100% 0;
}

.content {
  text-align: center;
  color: white;
  margin: auto;
}

.tagline-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 1.5rem 0;
}

@media (max-width: 767px) {
  .tagline-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.link-icon {
  color: white;
}

.link-icon:hover,
.link-icon:focus {
  color: #ff3333;
  transition: color 0.3s;
}

.accent-bar {
  border: 0;
  background: #ff3333;
  height: 5px;
  margin: 0.5rem 0;
}

h1 {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
  margin: 1.5rem 0;
}

p {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}
